body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.search-bar {
  background-color: #ffffff22;
  color: white;
  border: none;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 40px;
}

.search-bar:focus-visible {
  outline: 0px;
}

.search-bar::-webkit-input-placeholder {
  color: #bcbcbc;
}
.search-bar:-moz-placeholder {
  color: #bcbcbc;
}
.search-bar::-moz-placeholder {
  color: #bcbcbc;
}
.search-bar::-ms-input-placeholder {
  color: #bcbcbc;
}
